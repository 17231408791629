body {
    margin: 0;
}



/* Fonts
   ========================================================================== */
@font-face {
    font-family: 'Gotham Narrow Black';
    src: url('../public/assets/theme_font/GothamNarrow-Black.eot');
    src:
        url('../public/assets/theme_font/GothamNarrow-Black.eot?#iefix')
            format('embedded-opentype'),
        url('../public/assets/theme_font/GothamNarrow-Black.woff2')
            format('woff2'),
        url('../public/assets/theme_font/GothamNarrow-Black.woff')
            format('woff'),
        url('../public/assets/theme_font/GothamNarrow-Black.ttf')
            format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Narrow Medium';
    src: url('../public/assets/theme_font/GothamNarrow-Medium.eot');
    src:
        url('../public/assets/theme_font/GothamNarrow-Medium.eot?#iefix')
            format('embedded-opentype'),
        url('../public/assets/theme_font/GothamNarrow-Medium.woff2')
            format('woff2'),
        url('../public/assets/theme_font/GothamNarrow-Medium.woff')
            format('woff'),
        url('../public/assets/theme_font/GothamNarrow-Medium.ttf')
            format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Narrow Light';
    src: url('../public/assets/theme_font/GothamNarrow-Light.eot');
    src:
        url('../public/assets/theme_font/GothamNarrow-Light.eot?#iefix')
            format('embedded-opentype'),
        url('../public/assets/theme_font/GothamNarrow-Light.woff2')
            format('woff2'),
        url('../public/assets/theme_font/GothamNarrow-Light.woff')
            format('woff'),
        url('../public/assets/theme_font/GothamNarrow-Light.ttf')
            format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Narrow Book';
    src: url('../public/assets/theme_font/GothamNarrow-Book.eot');
    src:
        url('../public/assets/theme_font/GothamNarrow-Book.eot?#iefix')
            format('embedded-opentype'),
        url('../public/assets/theme_font/GothamNarrow-Book.woff2')
            format('woff2'),
        url('../public/assets/theme_font/GothamNarrow-Book.woff') format('woff'),
        url('../public/assets/theme_font/GothamNarrow-Book.ttf')
            format('truetype');
    font-weight: normal;
    font-style: normal;
}
